import { Box, Typography, IconButton, MenuItem } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useRouter } from 'next/router';
import CloseIcon from '@mui/icons-material/Close';
import AccountMenu from './AccountMenu';
import Image from 'next/image';
import theme from 'styles/theme';
import { Buttons } from '@motusehf/web-ui-component-library/dist';
import SidebarItem from '../Sidebar/SidebarItemType';
import { useAccountMenu } from 'src/Core/hooks/useAccountMenu';

export interface MobileMenuProps {
  sidebarItems: SidebarItem[];
}

export default function MobileMenu({ sidebarItems }: MobileMenuProps) {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { handleLogout } = useAccountMenu();

  const handleItemClick = (path: string) => {
    setIsOpen(false);
    router.push(path);
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={theme.palette.primary.dark}
      height={80}
    >
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => router.push('/')}
        sx={{ backgroundColor: 'transparent', marginLeft: '15px' }}
      >
        <Image src="/icons/motus_white.svg" alt="motus" width="76" height="27" priority={true} />
      </IconButton>
      {isOpen && (
        <Box
          sx={{
            position: 'absolute',
            top: '80px',
            width: '100%',
            backgroundColor: theme.palette.primary.dark,
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '30px 0',
          }}
        >
          {sidebarItems.map(({ name, icon, childItems, path }) => (
            <Box key={name + '-tab'} sx={{ paddingLeft: '25px' }}>
              <MenuItem
                onClick={() => {
                  if (!childItems || childItems.length === 0) {
                    handleItemClick(path);
                  }
                }}
              >
                {icon}
                <Typography sx={{ ml: 2, fontWeight: '400', fontSize: '30px', paddingLeft: '15px' }}>{name}</Typography>
              </MenuItem>
              {childItems &&
                childItems.map(({ name: childName, path: childPath }) => (
                  <MenuItem key={childName + '-tab'} onClick={() => handleItemClick(childPath)}>
                    <Typography
                      sx={{
                        ml: 2,
                        fontWeight: '400',
                        fontSize: '24px',
                        paddingLeft: '40px',
                        paddingTop: '10px',
                        paddingBottom: '20px',
                      }}
                    >
                      {childName}
                    </Typography>
                  </MenuItem>
                ))}
            </Box>
          ))}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '30px',
            }}
          >
            <Buttons
              type="PrimaryVariant"
              text="Útskráning"
              textColor="White"
              onClick={handleLogout}
              height={41}
              width={500}
            />
          </Box>
        </Box>
      )}
      <Box display="flex" alignItems="center" sx={{ marginRight: '30px' }}>
        {!isOpen && <AccountMenu isMobile />}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setIsOpen(!isOpen)}
          sx={{ paddingLeft: '15px' }}
        >
          {isOpen ? <CloseIcon sx={{ color: 'white' }} /> : <MenuIcon sx={{ color: 'white' }} />}
        </IconButton>
      </Box>
    </Box>
  );
}
