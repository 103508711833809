export default abstract class Constants {
  static readonly UMSYSLA_READ: string = 'UmsyslaRead';
  static readonly UMSYSLA_READWRITE: string = 'UmsyslaReadWrite';
  static readonly UMSYSLA_MIMIC: string = 'UmsyslaMimic';
  static readonly UMSYSLA_ADMIN: string = 'UmsyslaAdmin';
  static readonly CM_CLAIMVIEWER: string = 'ClaimViewer';
  static readonly CM_CLAIMCREATOR: string = 'ClaimCreator';
  static readonly CM_CLAIMADMIN: string = 'ClaimAdmin';
  static readonly CM_CLAIMINTERNALADMIN: string = 'ClaimInternalAdmin';
  static readonly DRAWER_WIDTH: number = 0;
}
