import { createContext, useContext, useMemo } from 'react';
import { useSession } from 'next-auth/react';
import constants from 'src/Umsysla/common/utils/constants';
import { Session } from 'next-auth';

interface AuthState {
  isAllowedToSave: boolean;
  hasAccessToUmsysla: boolean;
  hasAccessToClaimManagement: boolean;
  hasAccessToMimic: boolean;
  hasAdminAccess: boolean;
  hasAccessToEditCancelClaim: boolean;
  session: Session | null;
  status: 'authenticated' | 'loading' | 'unauthenticated';
  hasClaimInternalAdminAccess: boolean;
  checkRoleAccess: (allowedRoles: string[]) => boolean;
}

const AuthContext = createContext<AuthState>({
  isAllowedToSave: false,
  hasAccessToUmsysla: false,
  hasAccessToClaimManagement: false,
  hasAccessToMimic: false,
  hasAdminAccess: false,
  hasAccessToEditCancelClaim: false,
  session: null,
  status: 'loading',
  hasClaimInternalAdminAccess: false,
  checkRoleAccess: () => false,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: session, status: status } = useSession();
  const value = useMemo(() => {
    return {
      isAllowedToSave:
        (session?.user?.role?.includes(constants.UMSYSLA_READWRITE) ||
          session?.user?.role?.includes(constants.UMSYSLA_ADMIN)) ??
        false,
      hasAccessToUmsysla:
        session?.user?.role?.some((role) => role === constants.UMSYSLA_READ || role === constants.UMSYSLA_READWRITE) ??
        false,
      hasAccessToClaimManagement:
        session?.user?.role?.some(
          (role) =>
            role === constants.CM_CLAIMVIEWER ||
            role === constants.CM_CLAIMCREATOR ||
            role === constants.CM_CLAIMADMIN ||
            role === constants.CM_CLAIMINTERNALADMIN
        ) ?? false,
      hasAccessToMimic: session?.user?.role?.includes(constants.UMSYSLA_MIMIC) ?? false,
      hasAdminAccess: session?.user?.role?.includes(constants.UMSYSLA_ADMIN) ?? false,
      hasClaimInternalAdminAccess: session?.user?.role?.includes(constants.CM_CLAIMINTERNALADMIN) ?? false,
      hasAccessToEditCancelClaim:
        (session?.user?.role?.includes(constants.CM_CLAIMADMIN) ||
          session?.user?.role?.includes(constants.CM_CLAIMCREATOR)) ??
        false,
      session,
      status,
      checkRoleAccess: (allowedRoles: string[]) => {
        return session?.user?.role.some((role) => allowedRoles.includes(role));
      },
    } as AuthState;
  }, [session, status]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
