import { Box } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import { Footer } from '@motusehf/web-ui-component-library/dist';
import Sidebar from './Sidebar/Sidebar';
import { useEffect, useState } from 'react';
import { useAuthContext } from 'src/Core/hooks/AuthContext';
import LayoutHeader from 'src/Core/Layout/Header/LayoutHeader';
import { useIsMobile } from '../hooks/useIsMobile';
import { useNavigation } from '../hooks/NavigationContext';

export interface Layout {
  children: React.ReactNode;
}

export interface Layout {
  children: React.ReactNode;
}

export default function LayoutCore({ children }: Layout) {
  const [sidebarWidth] = useState(255);
  const [selectedTabThumbWidth] = useState(sidebarWidth + 8);
  const [isExpanded, setIsExpanded] = useState(true);
  const isMobile = useIsMobile();
  const { hasAccessToUmsysla, hasAccessToClaimManagement } = useAuthContext();
  const { setHasNavigated } = useNavigation();
  const handleExpandClick = () => {
    setIsExpanded((currentValue) => !currentValue);
  };

  useEffect(() => {
    setHasNavigated(true);
  }, []);

  const handleListOfSidebarItems = () => {
    return [
      {
        icon: <HomeIcon />,
        name: 'Forsíða',
        path: '/',
      },
      {
        icon: <ReceiptLongIcon />,
        name: 'Kröfustýring',
        path: '/krofustyring',
        childItems: [{ name: 'Yfirlit', path: '/krofustyring/yfirlit' }],
        isDisabled: !hasAccessToClaimManagement,
      },
      {
        icon: <SettingsOutlinedIcon />,
        name: 'Umsýsla',
        path: '/umsysla',
        childItems: [{ name: 'Aðgangsstýring', path: '/umsysla/adgangsstyring' }],
        isDisabled: !hasAccessToUmsysla,
      },
    ];
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', minHeight: '100vh' }}>
      {!isMobile && (
        <Sidebar
          items={handleListOfSidebarItems()}
          sidebarWidth={sidebarWidth}
          selectedTabThumbWidth={selectedTabThumbWidth}
          isExpanded={isExpanded}
          onExpandClick={handleExpandClick}
        />
      )}
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: `${isMobile ? 0 : sidebarWidth}px`,
        }}
      >
        <LayoutHeader sidebarItems={handleListOfSidebarItems()} />
        <Box
          sx={{
            ml: isMobile ? 0 : '60px',
            mr: { md: '1%', lg: '2%', xl: '6%' },
            minHeight: '70vh',
            flex: '1 0 auto',
          }}
        >
          <Box sx={{ p: 3 }}>{children}</Box>
        </Box>
        <Box sx={{ flexShrink: 0, position: 'sticky', top: '0' }}>
          <Footer
            companyTitle="Motus ehf."
            ssnText="kt. 701195-3109"
            addressText="Katrínartúni 4,"
            cityText="105 Reykjavík"
            phoneNumberText={
              isMobile ? (
                <span>
                  Sími{' '}
                  <a href="tel:4407700" style={{ color: 'inherit' }}>
                    440 7700
                  </a>
                </span>
              ) : (
                'Sími 440 7700'
              )
            }
            serviceCentreText="Þjónustuver"
            openingHoursText="Opið virka daga frá 10-16"
          />
        </Box>
      </Box>
    </Box>
  );
}
