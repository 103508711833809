import { Box, Typography, IconButton, Menu, Stack, Link, CircularProgress } from '@mui/material';
import theme from 'styles/theme';
import Image from 'next/image';
import { Buttons } from '@motusehf/web-ui-component-library/dist';

import CloseIcon from '@mui/icons-material/Close';
import { useAccountMenu } from 'src/Core/hooks/useAccountMenu';
import { trpcClient } from 'pages/api/trpc/_api';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface UserIdentity {
  name: string;
  email: string;
  organization: {
    name: string;
  };
}

interface UserClaimant {
  accountManager: {
    fullName: string;
    email: string;
    workPhone: string;
  };
}

export default function AccountMenu({ isMobile = false }) {
  const { handleOpenAccountMenu, handleCloseAccountMenu, handleLogout, isOpen, anchorElUser } = useAccountMenu();
  const [userData, setUserData] = useState<UserIdentity | null>(null);
  const [claimantData, setClaimantData] = useState<UserClaimant | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const userIdentity = await trpcClient.identity.getIdentity.query().catch(() => null);
      const userClaimant = await trpcClient.claimant.getClaimant.query().catch(() => null);

      if (userIdentity && userClaimant) {
        setUserData(userIdentity);
        setClaimantData(userClaimant);
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);

  function renderAccountManagerInfo() {
    return claimantData?.accountManager ? (
      <Box
        sx={{
          paddingTop: '1.5em',
          paddingLeft: '2em',
          paddingRight: '2em',
          paddingBottom: '2em',
          bgcolor: isMobile ? null : theme.palette.lightgrey.main,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" fontSize={18} color={theme.palette.primary.main} sx={{ pb: 0.5 }}>
          {claimantData?.accountManager.fullName}
        </Typography>
        <Typography variant="body2" fontSize={18} color={theme.palette.basicblack.main} sx={{ pb: 0.5 }}>
          er viðskiptastjórinn þinn
        </Typography>
        <Link
          href={`mailto:${claimantData?.accountManager.email}`}
          underline={isMobile ? 'always' : 'hover'}
          color={theme.palette.lightgreen.main}
        >
          <Typography variant="body2" sx={{ pb: 0.5, fontWeight: 600 }}>
            {claimantData?.accountManager.email}
          </Typography>
        </Link>
        <Typography variant="body2" fontSize={16} color={theme.palette.basicblack.main}>
          {claimantData?.accountManager.workPhone}
        </Typography>
      </Box>
    ) : null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 1,
        textAlign: 'center',
        alignItems: 'center',
        mr: '6%',
        gap: '1.5em',
      }}
    >
      <IconButton onClick={handleOpenAccountMenu} sx={{ p: 0, mr: 0.8 }}>
        {isMobile ? (
          isOpen ? (
            <CloseIcon sx={{ color: 'white' }} />
          ) : (
            <Image src="/icons/accountMenuIconV2white.svg" alt="menu" width="32" height="32" />
          )
        ) : (
          <Image src="/icons/accountMenuIcon.svg" alt="menu" width="40" height="41" />
        )}
      </IconButton>
      {isMobile && isOpen ? (
        <Box
          sx={{
            position: 'absolute',
            top: '80px',
            right: 0,
            left: 0,
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            color: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px 0',
          }}
        >
          <Image src="/icons/accountMenuIconV2.svg" alt="menu" width="32" height="32" />
          <Box sx={{ padding: '1em 3.5em', display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '0' }}>
            {isLoading && <CircularProgress />}
            <Typography variant="body1" fontSize={21} color={theme.palette.primary.main}>
              {userData?.name}
            </Typography>
            <Typography variant="body2" fontSize={18} color={theme.palette.primary.main}>
              {userData?.organization.name}
            </Typography>
          </Box>
          <Box
            component="li"
            sx={{ borderBottom: '2px solid', borderColor: 'divider', my: 2, width: '35%', mt: 0, mb: '3px' }}
          />
          {renderAccountManagerInfo()}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '30px',
              flexDirection: 'column',
              gap: '25px',
              paddingBottom: '2em',
            }}
          >
            {/* This buttons comes later */}
            {/* <Buttons
                type="Ghost"
                text="Mínar upplýsingar"
                textColor={theme.palette.primary.main}
                height={41}
                width="100%"
              ></Buttons> */}
            <Buttons
              type="PrimaryVariant"
              text="Útskráning"
              textColor="White"
              onClick={handleLogout}
              height={41}
              width="100%"
            ></Buttons>
          </Box>
        </Box>
      ) : (
        <Menu
          sx={{
            mt: 6,
            mr: 1,
            '& .MuiList-root': {
              paddingBottom: 0,
            },
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseAccountMenu}
        >
          <Box sx={{ padding: '1em 3.5em', display: 'flex', flexDirection: 'column', alignItems: 'center', pb: '0' }}>
            <Box>
              {isLoading && <CircularProgress />}

              <Typography variant="body1" sx={{ pt: '0.4em' }} fontSize={21}>
                {userData?.name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" fontSize={18} color={theme.palette.primary.main}>
                {userData?.organization?.name}
              </Typography>
            </Box>
          </Box>
          <Stack spacing={2} sx={{ padding: '2em 2em' }}>
            <Buttons
              type="Ghost"
              text="Stillingar"
              textColor={theme.palette.primary.main}
              onClick={() => {
                handleCloseAccountMenu();
                router.push('/stillingar');
              }}
              height={41}
            ></Buttons>
            <Buttons
              type="PrimaryVariant"
              text="Útskráning"
              textColor="White"
              onClick={handleLogout}
              height={41}
            ></Buttons>
          </Stack>
          {renderAccountManagerInfo()}
        </Menu>
      )}
    </Box>
  );
}
