import { useState } from 'react';
import { signOut } from 'next-auth/react';
import Router from 'next/router';
import { env } from 'src/Core/env.mjs';

export const useAccountMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>): void => {
    if (anchorElUser === event.currentTarget) {
      setAnchorElUser(null);
      setIsOpen(false);
    } else {
      setAnchorElUser(event.currentTarget);
      setIsOpen(true);
    }
  };

  const handleCloseAccountMenu = (): void => {
    setAnchorElUser(null);
    setIsOpen(false);
  };

  const handleLogout = (): void => {
    handleCloseAccountMenu();
    signOut({ redirect: false }).finally(() => {
      Router.push(`${env.STS_URL}/Account/Logout`);
    });
  };

  return {
    anchorElUser,
    isOpen,
    handleOpenAccountMenu,
    handleCloseAccountMenu,
    handleLogout,
  };
};
