import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import { SessionProvider } from 'next-auth/react';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ToastContainer } from 'react-toastify';
import theme from '../styles/theme';
import createEmotionCache from '../styles/createEmotionCache';
import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import { initalizeMonitoring } from 'infrastructure/monitoring';
import { api } from './api/trpc/_api';
import AuthenticationGuard from 'src/Core/auth/authenticationGuard';
import LayoutCore from 'src/Core/Layout/LayoutCore';
import { NavigationProvider } from 'src/Core/hooks/NavigationContext';
import { ClaimsFilterProvider } from 'src/Core/hooks/ClaimsFilterContext';
import { AuthProvider } from 'src/Core/hooks/AuthContext';

initalizeMonitoring();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

function MyApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { session, ...pageProps },
  } = props;
  return (
    <SessionProvider session={session} refetchInterval={5 * 60}>
      <AuthProvider>
        <CacheProvider value={emotionCache}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <NavigationProvider>
              <ClaimsFilterProvider>
                <AuthenticationGuard>
                  <LayoutCore>
                    <Component {...pageProps} />
                  </LayoutCore>
                </AuthenticationGuard>
              </ClaimsFilterProvider>
            </NavigationProvider>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              progressClassName="toastProgressBar"
            />
          </ThemeProvider>
        </CacheProvider>
      </AuthProvider>
    </SessionProvider>
  );
}

export default api.withTRPC(MyApp);
