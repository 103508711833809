/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme } from '@mui/material/styles';

import { Theme } from '@mui/material/styles';

export interface ThemeInterface {
  theme: Theme;
}

declare module '@mui/material/styles' {
  interface Palette {
    lightgrey: Palette['primary'];
    darkgrey: Palette['primary'];
    basicblack: Palette['primary'];
    mediumgrey: Palette['primary'];
    danger: Palette['primary'];
    lightgreen: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    lightgrey?: PaletteOptions['primary'];
    darkgrey?: PaletteOptions['primary'];
    basicblack?: PaletteOptions['primary'];
    mediumgrey?: PaletteOptions['primary'];
    danger?: PaletteOptions['primary'];
    lightgreen?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    lightgrey: true;
  }
}

// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#283C32',
      dark: '#375046',
    },
    secondary: {
      main: '#73AF69',
    },
    lightgrey: {
      main: '#eeeeee',
    },
    mediumgrey: {
      main: '#909090',
    },
    darkgrey: {
      main: '#666666',
    },
    lightgreen: {
      main: '#73AF69',
    },
    basicblack: {
      main: '#333333',
    },
    background: {
      default: 'lightgrey',
      paper: '#ffffff',
    },
    danger: {
      main: '#B30000',
    },
    success: {
      main: '#008024',
    },
    info: {
      main: '#024DBC',
    },
    warning: {
      main: '#FD7E14',
    },
    error: {
      main: '#CD5F3C',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Brandon Text W01 Light',
    body1: {
      fontSize: 16,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    body2: {
      fontSize: 16,
      fontFamily: 'Brandon Text W01 Regular',
      fontWeight: 'normal',
    },
    h1: {
      fontSize: 40,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 32,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 28,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 24,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 20,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: 16,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
    button: {
      fontSize: 14,
      fontFamily: 'Brandon Text W01 Medium',
      fontWeight: 'bold',
    },
  },
});

export default theme;
