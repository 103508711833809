import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Header from './Header';
import theme from '../../../../styles/theme';
import { useIsMobile } from 'src/Core/hooks/useIsMobile';
import MobileMenu from './MobileMenu';
import SidebarItem from '../Sidebar/SidebarItemType';

export interface LayoutHeaderProps {
  sidebarItems: SidebarItem[];
}

export default function LayoutHeader({ sidebarItems }: LayoutHeaderProps) {
  const [isSticky, setSticky] = useState(false);
  const isMobile = useIsMobile();
  const checkScrollTop = () => {
    setSticky(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  }, []);

  return (
    <Box
      sx={{
        mr: '5%)',
        position: 'sticky',
        top: '0',
        zIndex: '999',
        backgroundColor: theme.palette.lightgrey.main,
        boxShadow: isSticky ? '0px 10px 10px -10px rgba(0, 0, 0, 0.2)' : 'none',
      }}
    >
      {!isMobile ? <Header /> : <MobileMenu sidebarItems={sidebarItems} />}
    </Box>
  );
}
