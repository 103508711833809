import React, { useReducer, useContext, createContext, Dispatch } from 'react';
import ClaimSearch from 'src/Krofustyring/claimsOverview/claims/models/ClaimSearchResult';
import { ClaimSearchRequest } from 'src/Krofustyring/claimsOverview/claims/models/claimSearchRequest';

export interface ClaimsFilterState {
  searchBody: ClaimSearchRequest;
  selected: string[];
  accountClaimantInput: string;
  payorInput: string;
  isSearchChanged: boolean;
  sortField: keyof ClaimSearch | null;
  sortDirection: 'asc' | 'desc';
}

export type ClaimsFilterAction =
  | { type: 'SET_SEARCH_BODY'; isPayload: Partial<ClaimSearchRequest> }
  | { type: 'SET_SELECTED'; isPayload: string[] }
  | { type: 'SET_ACCOUNT_CLAIMANT_INPUT'; isPayload: string }
  | { type: 'SET_PAYOR_INPUT'; isPayload: string }
  | { type: 'SET_IS_SEARCH_CHANGED'; isPayload: boolean }
  | { type: 'SET_SORT'; isPayload: { field: keyof ClaimSearch | null; direction: 'asc' | 'desc' } }
  | { type: 'RESET_FILTERS' };

const defaultSearchRequest: ClaimSearchRequest = {
  search: '',
  account: '',
  claimantSsn: '',
  payorSsn: '',
  claimantName: '',
  payorName: '',
  dueDateFrom: '',
  dueDateTo: '',
  finalDueDateFrom: '',
  finalDueDateTo: '',
  amountFrom: 0,
  amountTo: 0,
  statuses: null,
  collectionStates: null,
  creationDateFrom: '',
  creationDateTo: '',
  reference: '',
  customerNumber: '',
  billNumber: '',
  templateCode: '',
  pageSize: 12,
  pageNumber: 0,
  sortField: null,
  sortDirection: 'asc',
};

const initialState: ClaimsFilterState = {
  searchBody: defaultSearchRequest,
  selected: [],
  accountClaimantInput: '',
  payorInput: '',
  isSearchChanged: false,
  sortField: null,
  sortDirection: 'asc',
};

const ClaimsFilterStateContext = createContext<ClaimsFilterState | undefined>(undefined);
const ClaimsFilterDispatchContext = createContext<Dispatch<ClaimsFilterAction> | undefined>(undefined);

const claimsFilterReducer = (state: ClaimsFilterState, action: ClaimsFilterAction): ClaimsFilterState => {
  switch (action.type) {
    case 'SET_SEARCH_BODY':
      return { ...state, searchBody: { ...state.searchBody, ...action.isPayload } };
    case 'SET_SELECTED':
      return { ...state, selected: action.isPayload };
    case 'SET_ACCOUNT_CLAIMANT_INPUT':
      return { ...state, accountClaimantInput: action.isPayload };
    case 'SET_PAYOR_INPUT':
      return { ...state, payorInput: action.isPayload };
    case 'SET_IS_SEARCH_CHANGED':
      return { ...state, isSearchChanged: action.isPayload };
    case 'SET_SORT':
      return {
        ...state,
        sortField: action.isPayload.field,
        sortDirection: action.isPayload.direction,
        searchBody: {
          ...state.searchBody,
          sortField: action.isPayload.field,
          sortDirection: action.isPayload.direction,
        },
      };
    case 'RESET_FILTERS':
      return initialState;
    default:
      return state;
  }
};

export const ClaimsFilterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(claimsFilterReducer, initialState);

  return (
    <ClaimsFilterStateContext.Provider value={state}>
      <ClaimsFilterDispatchContext.Provider value={dispatch}>{children}</ClaimsFilterDispatchContext.Provider>
    </ClaimsFilterStateContext.Provider>
  );
};

export function useClaimsFilterState(): ClaimsFilterState {
  const context = useContext(ClaimsFilterStateContext);
  if (!context) throw new Error('useClaimsFilterState must be used within a ClaimsFilterProvider');
  return context;
}

export function useClaimsFilterDispatch(): Dispatch<ClaimsFilterAction> {
  const context = useContext(ClaimsFilterDispatchContext);
  if (!context) throw new Error('useClaimsFilterDispatch must be used within a ClaimsFilterProvider');
  return context;
}
