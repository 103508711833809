import { Box, CircularProgress, Typography } from '@mui/material';
import { signIn } from 'next-auth/react';
import { useAuthContext } from 'src/Core/hooks/AuthContext';

interface AuthenticationGuardProps {
  children: React.ReactNode;
}

export default function AuthenticationGuard({ children }: AuthenticationGuardProps) {
  const { session, status } = useAuthContext();
  if (status === 'loading') {
    return (
      <Box sx={{ position: 'absolute', margin: 0, top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress />
        <Typography sx={{ paddingTop: '45px', transform: 'translate(-35%, -50%)' }} variant="body1">
          Vinn úr auðkenningu...
        </Typography>
      </Box>
    );
  }

  if (status === 'unauthenticated' || session?.error) {
    signIn('demo-identity-server');
    return null;
  }

  return <>{children}</>;
}
