import React, { createContext, useContext, useState } from 'react';

interface NavigationContextProps {
  hasNavigated: boolean;
  setHasNavigated: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultNavigationContext: NavigationContextProps = {
  hasNavigated: false,
  setHasNavigated: () => 0,
};

const NavigationContext = createContext<NavigationContextProps>(defaultNavigationContext);

export const useNavigation = () => {
  return useContext(NavigationContext);
};

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasNavigated, setHasNavigated] = useState<boolean>(false);
  return <NavigationContext.Provider value={{ hasNavigated, setHasNavigated }}>{children}</NavigationContext.Provider>;
};
