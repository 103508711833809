import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../../../../styles/theme';
import SidebarItem from './SidebarItemType';
import { useRouter } from 'next/router';
import { useIsMobile } from '../../hooks/useIsMobile';

const styles = {
  selectedItem: {
    display: 'flex',
    flexDirection: 'row',
    height: '65px',
    alignItems: 'center',
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    boxShadow: '0px 4px 6px rgba(153, 153, 153, 0.8)',
    borderRadius: '0px 20px 20px 0px',
    pl: 3,
    cursor: 'pointer',
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: '65px',
    alignItems: 'center',
    ml: 3,
  },
  disabledItem: {
    display: 'flex',
    flexDirection: 'row',
    height: '65px',
    alignItems: 'center',
    ml: 3,
    opacity: 0.5,
  },
  subItem: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: '65px',
    alignItems: 'center',
    pl: '68px',
    width: '100%',
    borderBottom: '0.5px solid #D3D3D3',
  },
  selectedSubItem: {
    fontWeight: 700,
    backgroundColor: '#383f3b05',
  },
};

export interface SidebarProps {
  items: SidebarItem[];
  sidebarWidth: number;
  handleSetSidebarWidth?: (value: number) => void;
  selectedTabThumbWidth?: number;
  isExpanded?: boolean;
  onExpandClick: () => void;
}

export default function Sidebar({
  items,
  sidebarWidth = 200,
  selectedTabThumbWidth = sidebarWidth - 15,
  isExpanded = true,
}: SidebarProps) {
  const router = useRouter();
  const [selectedTab, setSelectedTab] = useState<string>(router.asPath);
  const [selectedParent, setSelectedParent] = useState<string | null>(null);
  // Initialize activeParents with all parent paths
  const [activeParents, setActiveParents] = useState<Set<string>>(new Set(items.map((item) => item.path)));
  const isMobile = useIsMobile();

  useEffect(() => {
    setSelectedTab(router.asPath);
    setSelectedParent(null);
  }, [router.asPath]);

  const isSubpath = (mainPath: string, subPath: string) => {
    const normalizePath = (path: string) => (path === '/' ? path : path.replace(/\/+$/, ''));
    mainPath = normalizePath(mainPath);
    subPath = normalizePath(subPath);

    if (subPath === '/') {
      return mainPath === '/';
    }

    return mainPath === subPath || mainPath.startsWith(subPath + '/');
  };

  const renderedItems = items.map(({ name, path, icon, childItems, isDisabled }) => {
    const text = isExpanded && <Typography sx={{ ml: 2 }}>{name}</Typography>;
    const isParentOfSelectedTab = (path: string) => {
      return isSubpath(selectedTab, path);
    };

    return (
      <React.Fragment key={name + '-tab'}>
        <Box
          sx={
            (selectedParent === path && selectedTab.startsWith(path)) || isParentOfSelectedTab(path)
              ? styles.selectedItem
              : isDisabled
                ? styles.disabledItem
                : styles.item
          }
          width={selectedTabThumbWidth}
          onClick={(event) => {
            event.stopPropagation();
            if (!isDisabled) {
              if (!childItems || childItems.length === 0) {
                if (!isMobile) {
                  handleClick(path);
                }
              } else {
                if (activeParents.has(path)) {
                  activeParents.delete(path);
                } else {
                  activeParents.add(path);
                }
                setActiveParents(new Set(activeParents));
              }
              setSelectedParent(path);
            }
          }}
        >
          {icon}
          {text}
        </Box>

        {childItems &&
          !isDisabled &&
          isExpanded &&
          activeParents.has(path) &&
          childItems.map(({ path: childPath, name: childName }) => (
            <Box
              key={childName + '-subtab'}
              sx={{ ...styles.subItem, fontWeight: selectedTab === childPath ? '700' : '400' }}
              onClick={(event) => {
                event.stopPropagation();
                handleClick(childPath);
              }}
            >
              {childName}
            </Box>
          ))}
      </React.Fragment>
    );
  });

  const handleClick = (iconPath: string) => {
    if (iconPath) {
      const path = iconPath.startsWith('/') ? iconPath : '/' + iconPath;
      router.push(path);
      setSelectedParent(iconPath);
    }
  };

  return (
    <Box
      sx={{
        width: `calc(${sidebarWidth}px + 10px)`,
        background: 'transparent',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: sidebarWidth,
          height: '100vh',
          justifyContent: 'space-between',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 4px 4px rgba(153, 153, 153, 0.25)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: 4,
          }}
        >
          {renderedItems}
        </Box>
      </Box>
    </Box>
  );
}
